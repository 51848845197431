import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import useSiteMetadata from "../hooks/use-site-metadata";

type SEOProps = {
  title?: string;
  description?: string;
  pathname?: string;
  image?: string;
  children?: React.ReactNode;
  canonicalUrl?: string;
  siteType: string;
  publishDate: string;
};

export const parseDate = (date) => {
  const day = date.substring(3, 5);
  const month = date.substring(0, 2);
  const year = 20 + date.substring(8, 10);

  return year + "-" + month + "-" + day + "T00:00:00.000Z";
};

const SEO = ({
  title = ``,
  description = ``,
  pathname = ``,
  image = ``,
  children = null,
  canonicalUrl = ``,
  publishDate = ``,
  siteType = `website`,
}: SEOProps) => {
  const site = useSiteMetadata();

  const {
    siteTitle,
    siteTitleAlt: defaultTitle,
    siteUrl,
    siteDescription: defaultDescription,
    siteLanguage,
    siteImage: defaultImage,
    author,
    authorFullName,
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    siteType: siteType || `website`,
    siteTitle: siteTitle,
    authorFullName: authorFullName || "",
    publishDate: publishDate || ``,
    url: `${siteUrl}${pathname || ``}`,
    image: `${siteUrl}${image || defaultImage}`,
  };

  return (
    <Helmet
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={siteLanguage} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.publishDate && (
        <meta
          property="article:published_time"
          content={parseDate(seo.publishDate)}
        />
      )}
      {seo.siteType == "article" && (
        <meta property="article:author" content={seo.authorFullName} />
      )}
      {seo.siteType == "article" && (
        <meta name="author" content={seo.authorFullName} />
      )}
      <meta property="og:title" content={seo.title} />
      <meta property="og:site_name" content={seo.siteTitle} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:type" content={seo.siteType} />
      <meta property="og:image:alt" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.description} />
      <meta name="twitter:creator" content={author} />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={withPrefix(`/favicon-32x32.png`)}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={withPrefix(`/favicon-16x16.png`)}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={withPrefix(`/apple-touch-icon.png`)}
      />
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      {children}
    </Helmet>
  );
};

export default SEO;
